<template>
  <v-container class="text-left">
    <v-row>
      <v-col>
        <div class="text-h2">Cookie policy</div>
        <br />
        <div class="text-h4">1. What is a Cookie?</div>
        <br />
        <br />
        <div class="text-h7">
          A cookie is a small file of letters and numbers downloaded on to your
          computer when you access certain websites. In general, cookies allow a
          website to recognise a user’s computer.
        </div>
        <br />
        <div class="text-h7">
          The most important thing to know about cookies placed by Hive is that
          they make our website a bit more user-friendly, for example, by
          remembering site preferences and language settings.
        </div>
        <br />
        <div class="text-h4">2. Why does Hive use Cookies?</div>
        <br />
        <br />

        <div class="text-h7">
          We use these cookies and other similar technologies for a number of
          purposes, including:
        </div>
        <br />
        <v-col>
          <ul>
            <li>Keeping you signed in</li>
            <li>Keeping your preferences</li>
            <li>
              To provide you with the service you chose to receive from us
            </li>
            <li>Understanding how you use our website</li>
            <li>
              To monitor and analyze the performance, operation, and
              effectiveness in order to improve Hive
            </li>
            <li>
              To understand and improve the impact of our marketing campaigns
            </li>
            <li>
              For security needs and fraud-protection purposes and in order to
              identify and prevent cyber-attacks
            </li>
          </ul>
        </v-col>

        <br />

        <div class="text-h7">
          Cookies that are used by Avilys.draudimobites.lt should be divided
          into two types:
        </div>
        <br />
        <v-col>
          <ul>
            <li>“First-party Cookies” - Cookies that are placed by Hive</li>
            <li>
              “Third-party Cookies” - Cookies that are placed and used by
              third-party companies which are Hive's partners
            </li>
          </ul>
        </v-col>

        <div class="text-h7">
          It is important to note that Avilys.draudimobites.lt cannot access
          third-party cookies; nor can other third-party organizations access
          the data in the cookies we use on our website. Hive doesn't allow
          third-parties to use the cookies for any purpose other than those
          described in the next sections.
        </div>

        <br />
        <br />
        <div class="text-h4">Duration:</div>
        <br />
        <div class="text-h7">
          Depending on their function, Cookies may have different durations.
          There are session cookies and persistent cookies:
        </div>
        <v-col>
          <ol>
            <li>
              Session cookies only last for your online session. It means that
              the browser deletes these cookies once you close your browser
            </li>
            <li>
              Persistent cookies stay on your device after the browser has been
              closed and last for the period of time specified in the cookie
            </li>
          </ol>
        </v-col>
        <br />
        <br />
        <div class="text-h4">Categories:</div>
        <br />
        <div class="text-h7">
          The cookies used on our website fall into one of four categories:
        </div>
        <v-col>
          <ol>
            <li>
              <strong>Essential Cookies</strong>
              let you move around the website and use essential features like
              secure and private areas.
            </li>
            <li>
              <strong>Analytic Cookies</strong>
              let us understand how you use our website (e.g. which pages you
              visit), to provide statistics on how our website is used, improve
              the website by identifying any errors, and performance issues.
            </li>
            <li>
              <strong>Functional cookies</strong>
              are cookies used to remember choices users make to improve their
              experience (e.g. language).
            </li>
            <li>
              <strong>Marketing cookies</strong>
              are used to collect information about the impact of our marketing
              campaigns performed in other websites on users and non-users.
            </li>
          </ol>
        </v-col>
        <br />
        <div class="text-h4">3. Hive Cookies - table:</div>
        <br />
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Purpose</th>
                <th class="text-left">Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in cookies" :key="item.name">
                <td>{{ item.name }}</td>
                <td>{{ item.purpose }}</td>
                <td>{{ item.duration }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <br />

        <div class="text-h4">4. Your choices:</div>
        <div class="text-h7">
          To find out more about cookies, including how to see what cookies have
          been set and understand how to manage, delete and block them, visit
          www.aboutcookies.org or www.allaboutcookies.org.
        </div>
        <div class="text-h7">
          Alternately, it is also possible to stop your browser from accepting
          cookies altogether by changing your browser’s cookie settings. You can
          usually find these settings in the “options” or “preferences” menu of
          your browser.
        </div>
        <div class="text-h7">
          Please note that deleting our cookies, disabling future cookies or
          tracking technologies may prevent you from accessing certain areas or
          features of our Services, or may otherwise adversely affect your user
          experience.
        </div>
        <div class="text-h7">
          The following links may be helpful, or you can use the “Help” option
          in your browser.
        </div>
        <br />
        <ul>
          <li>
            <a
              href="http://support.mozilla.com/en-US/kb/Enabling%20and%20disabling%20cookies"
              target="_blank"
            >
              Cookie settings in Firefox</a
            >
          </li>

          <li>
            <a
              href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              >Cookie settings in Internet Explorer</a
            >
          </li>

          <li>
            <a
              href="http://www.google.com/support/chrome/bin/answer.py?answer=95647"
              target="_blank"
              >Cookie settings in Google Chrome</a
            >
          </li>

          <li>
            <a
              href="http://support.apple.com/kb/PH17191?viewlocale=en_US&locale=en_US"
              target="_blank"
              >Cookie settings in Safari (OS X)</a
            >
          </li>

          <li>
            <a href="https://support.apple.com/en-us/HT201265" target="_blank"
              >Cookie settings in Safari (iOS)</a
            >
          </li>

          <li>
            <a
              href="https://support.google.com/accounts/answer/32050?co=GENIE.Platform%3DAndroid&hl=en"
              target="_blank"
              >Cookie settings in Android</a
            >
          </li>
        </ul>
        <br />
        <div class="text-h7">
          To opt out from and prevent your data from being used by Google
          Analytics across all websites, check out the following instructions:
          <a href="https://tools.google.com/dlpage/gaoptout" target="_blank"
            >https://tools.google.com/dlpage/gaoptout</a
          >.
        </div>
        <div class="text-h7">
          We may update this Cookie Policy. We encourage you to periodically
          review this page for the latest information about cookies set by
          Avilys.draudimobites.lt.
        </div>
        <div class="text-h7"></div>
        <div class="text-h7"></div>
        <div class="text-h7"></div>
        <div class="text-h7"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      cookies: [
        {
          name: 'hive-auth',
          purpose: 'User for user authentication.',
          duration: '1 day',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped></style>
